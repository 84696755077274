import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import { SupportParamsFilter, SupportParamsResponse } from "./types";

export async function tariffManualListService(
  filters: SupportParamsFilter & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<SupportParamsResponse[]>>(
    "/contractSupports/",
    { params: filters },
  );
  return res.data;
}

// export async function createSupportService(payload: SupportParamsPayload, token: string) {
//   const axiosInstance = HTTP_DISPENSARY(token);
//   const response = await axiosInstance.post<IResponse>(`/contractSupports/`, payload);
//   return response.data;
// }
// export async function updateSupportService(payload: SupportUpdatePayload, token: string) {
//   const axiosInstance = HTTP_DISPENSARY(token);
//   const response = await axiosInstance.put<IResponse>(`/contractSupports/${payload.id}`, payload);
//   return response.data;
// }

// export async function commentsSupportService(id: number, token: string) {
//   const res = await HTTP_DISPENSARY(token).get<IResponse<commentsSupportsREsponse[]>>(
//     `/commentsDispensarySupport/history/${id}`,
//   );
//   return res.data;
// }
