import { Portal } from "@mantine/core";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useLoading } from "@app/common/hooks/useLoading";
import { useGetCategories } from "@app/services/category";
import { useAuthentication } from "@app/store/useAuthtentication";

import AppLoader from "@app/common/components/AppLoader";
import Header from "@app/common/components/Header";
import Sidebar from "@app/common/components/Sidebar";

import "./styles.scss";

export default function Features() {
  const isLoading = useLoading();
  const location = useLocation();
  const profielId = useAuthentication(state => state.auth.dataBiowel.idProfile);
  const moduleId = useAuthentication(state => state.auth.dataBiowel.idModule);

  const setCategories = useAuthentication(state => state.setCategories);

  const categoriesQuery = useGetCategories({
    id_profile: profielId,
    categoryId: moduleId,
  });

  const applyClass = () => {
    const excludedPaths = [
      "/dispensation/drugDelivery/particular",
      "/dispensation/drugDelivery/eps/order",
      "/dispensation/drugDelivery/patientOrderInformation",
      "/dispensation/drugDelivery/dispensary/detailOrder",
      "/dispensation/deliveryAuth/detail",
      "/dispensation/supportParameters/create",
      "/dispensation/supportParameters/edit",
      "/dispensation/store",
    ];

    return !excludedPaths.some(path => location.pathname === path);
  };

  useEffect(() => {
    if (categoriesQuery.data) {
      setCategories(categoriesQuery.data.all_Categories);
    }
  }, [categoriesQuery.data, setCategories]);

  const renderComponent = () => {
    return (
      <>
        {isLoading && (
          <Portal target="#app-loader">
            <AppLoader />
          </Portal>
        )}
        <div className="app-container">
          <Header />

          <div className="app-viewport">
            <Sidebar />
            <main
              id="mainContainer"
              className={`${applyClass() ? "main-container" : "main-container-noP"}`}
            >
              <Outlet />
            </main>
          </div>
        </div>
      </>
    );
  };

  return renderComponent();
}
