import AppPagination from "@app/common/components/AppPagination";
import { TextField } from "@app/common/components/TextField";
import { IFiltersPagination } from "@app/common/types/http";
import { useGetSupportParamsList } from "@app/services/supportParams/hooks";
import { SupportParamsFilter } from "@app/services/supportParams/types";
import { ActionIcon, Box, Grid, Table, Title } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconChevronRight, IconSearch } from "@tabler/icons-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const DispensaryStore = () => {
  const location = useLocation();
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & SupportParamsFilter>(
    { contractId: null, corporateClient: null },
    500,
  );
  const action = location?.state?.action ?? "";
  const { contractId, corporateClient } = location?.state?.data ?? {};

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSupportParamsList({
    ...filters,
    perpage: 10,
  });

  //   const {
  //     data: companyList = {
  //       results: [],
  //       rowTotal: 0,
  //     },
  //   } = useGetCompanyClientList({
  //     status: "enabled",
  //     eaccount: GCidAccount(),
  //   });

  //   const {
  //     data: contractList = {
  //       results: [],
  //       rowTotal: 0,
  //     },
  //   } = useGetContractList(
  //     {
  //       corporateClient: Number(filters.corporateClient),
  //       slim: 1,
  //     },
  //     filters.corporateClient !== null,
  //   );

  useEffect(() => {
    if (action === "edit") {
      setFilters({
        contractId,
        corporateClient,
      });
    }
  }, [contractId, corporateClient, action]);

  const filterSection = () => {
    return (
      <>
        <Box className="searchSection" w={"35%"}>
          <Box px={"xl"}>
            <Box className="flex gap-2">
              <Title
                pt={"xl"}
                mt={"sm"}
                pb={"xs"}
                size={"h1"}
                w={"100%"}
                c="secondary-color"
                fw={"bold"}
                ta={"start"}
                className=""
              >
                Almacenes
              </Title>
            </Box>
            <Grid>
              <Grid.Col span={12}>
                <TextField
                  placeholder="Escribe aquí para buscar..."
                  variant="filled"
                  endIcon={<IconSearch className="pointer" />}
                  className="flex-grow-1 me-4"
                  name="search"
                  onChange={({ target }) =>
                    setFilters(state => ({
                      ...state,
                      search: target.value,
                    }))
                  }
                />
              </Grid.Col>
            </Grid>
            <Box py={"sm"}>
              {renderTable()}
              <AppPagination
                total={supportList?.rowTotal || 0}
                value={filters.page || 1}
                onChange={value => setFilters(state => ({ ...state, page: value }))}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const renderRow = (option: (typeof supportList.results)[number]) => {
    return (
      <Table.Tr key={option?.corporateClientId}>
        <Table.Td>{option?.corporateClientName}</Table.Td>

        <Table.Td>{option?.contractName}</Table.Td>

        <Table.Td ta="end">
          <ActionIcon variant="subtle">
            <IconChevronRight />
          </ActionIcon>
        </Table.Td>
      </Table.Tr>
    );
  };
  const renderRowConsults = (option: (typeof supportList.results)[number]) => {
    const date = (option?.createdAt ?? "").split("T")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={option?.corporateClientId}>
        <Table.Td>{option?.contractId}</Table.Td>

        <Table.Td>{option?.contractName}</Table.Td>
        <Table.Td>{option?.contractName}</Table.Td>
        <Table.Td>{option?.contractName}</Table.Td>
        <Table.Td>{option?.contractName}</Table.Td>

        <Table.Td ta={"center"}>{option?.supports?.length}</Table.Td>
        <Table.Td ta={"center"}>{date}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Código</Table.Th>
            <Table.Th>Nombre almacén</Table.Th>

            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length > 0 ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };
  const renderTableConsult = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th bg={"#fff"} ta={"center"}>
              Código
            </Table.Th>
            <Table.Th bg={"#fff"}>Artículo activo</Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Propias
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"start"}>
              Consignadas
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Vence
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              CUMS
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"start"}>
              Lote
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length > 0 ? (
            supportList?.results?.map(renderRowConsults)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const supportListSection = () => {
    return (
      <Box w={"100%"}>
        <Title
          size={"h3"}
          w={"100%"}
          c="brand-color"
          pb={"sm"}
          fw={"bold"}
          ta={"start"}
          className="flex "
        >
          Consultas de existencia
        </Title>
        <Box py={"lg"}>
          <Grid className="secondary-color">
            <Grid.Col span={2}>
              <b>Propias</b>
            </Grid.Col>
            <Grid.Col span={4}>1920</Grid.Col>
            <Grid.Col span={2}>
              <b>Consignación</b>
            </Grid.Col>
            <Grid.Col span={4}>0</Grid.Col>
          </Grid>
        </Box>
        {renderTableConsult()}
        <AppPagination
          total={supportList?.rowTotal || 0}
          value={filters.page || 1}
          onChange={value => setFilters(state => ({ ...state, page: value }))}
        />
      </Box>
    );
  };
  const renderComponent = () => {
    return (
      <Fragment>
        <div className="bodyOrder">
          {filterSection()}
          <Box className="renderSection main-container">
            <>{supportListSection()}</>
          </Box>
        </div>
      </Fragment>
    );
  };

  return renderComponent();
};

export default DispensaryStore;
