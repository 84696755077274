import { formattedDataSelect } from "@app/common/utils/utils";
import { useGetDocumentTypes } from "@app/services/common/hooks";
import { Box, Button, Drawer, Select, TextInput } from "@mantine/core";

const DeliveryDetailDrawer = ({
  isOpen,
  onClose,
  //dataPatient,
}: {
  isOpen: boolean;
  //dataPatient?: OrderInfo;
  onClose: () => void;
}) => {
  const {
    data: documentType = {
      results: [],
      rowTotal: 0,
    },
  } = useGetDocumentTypes();

  const closeDrawer = () => {
    onClose();
  };

  return (
    <Drawer opened={isOpen} onClose={closeDrawer} title={"Detalle domicilio"} size={"xs"}>
      <Box className="flex flex-column justify-between" h={"100%"}>
        <Box className="cardSearch shadow" p={0} bd={0}>
          <Box p={8} ta={"center"} className="primary-color shadow">
            <b>Cancelado</b>
          </Box>
          <Box bg={"#fff"} p={8}>
            <b>Justificación</b>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minima reprehenderit
              voluptatem id accusamus ipsa facilis saepe, officia asperiores cumque ad quibusdam
              expedita. Nesciunt numquam corporis vel, asperiores perspiciatis doloribus placeat.
            </p>
            <span>30/07/2020 17:67</span>
          </Box>
        </Box>
        <Box w={"100%"}>
          <Select
            label="Domiciliario asignado"
            data={formattedDataSelect(documentType.results, "description", "id")}
            data-testid="docType"
            disabled
            placeholder="Oscar Luis"
          />

          <TextInput
            type="text"
            disabled
            label="Persona que recibe"
            data-testid="idPatient"
            placeholder="Escribir..."
          />

          <TextInput data-testid="insurance" label="Dirección" disabled placeholder="Escribir..." />
          <TextInput
            data-testid="phonePatient"
            disabled
            label="Barrio"
            type="text"
            placeholder="Escribir..."
          />

          <TextInput
            type="email"
            label="Teléfono"
            data-testid="emailPatient"
            placeholder="Escribir..."
            disabled
          />
          <TextInput
            type="email"
            label="Email"
            disabled
            data-testid="emailPatient"
            placeholder="Escribir..."
          />
        </Box>
        <Box className="flex justify-center gap-3" py={"xl"}>
          <Button variant="filled" onClick={closeDrawer} data-testid="cancelFullRequest">
            Aceptar
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DeliveryDetailDrawer;
