import AppPagination from "@app/common/components/AppPagination";
import { Template } from "@app/common/components/Template";
import { TextField } from "@app/common/components/TextField";
import { IFiltersPagination } from "@app/common/types/http";
import routes from "@app/config/routes";

import { SupportParamsFilter } from "@app/services/supportParams/types";
import { useGetTariffManual } from "@app/services/tariffManual/hooks";
import { ActionIcon, Button, Grid, Menu, Table } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconCirclePlus,
  IconDetails,
  IconDotsVertical,
  IconPencil,
  IconSearch,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const TariffManual = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & SupportParamsFilter>(
    { contractId: null, corporateClient: null },
    500,
  );

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetTariffManual({
    ...filters,
    perpage: 10,
  });

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={6}>
          <TextField
            placeholder="Escribe aquí para buscar..."
            variant="filled"
            endIcon={<IconSearch className="pointer" />}
            className="flex-grow-1 me-4"
            name="search"
            onChange={({ target }) =>
              setFilters(state => ({
                ...state,
                search: target.value,
              }))
            }
          />
        </Grid.Col>

        <Grid.Col span={6} className="flex justify-end">
          <Button
            variant="subtle"
            rightSection={<IconCirclePlus />}
            onClick={() =>
              navigate(routes.getTariffManualCreate, {
                state: { action: "create" },
              })
            }
          >
            Crear manual
          </Button>
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof supportList.results)[number]) => {
    const date = (option?.createdAt ?? "").split("T")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={option?.corporateClientId}>
        <Table.Td>{option?.corporateClientName}</Table.Td>

        <Table.Td>{option?.contractName}</Table.Td>

        <Table.Td ta={"center"}>{option?.supports?.length}</Table.Td>
        <Table.Td ta={"center"}>{date}</Table.Td>
        <Table.Td ta="end">
          <Menu position="bottom-end" withArrow>
            <Menu.Target>
              <ActionIcon variant="subtle">
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconPencil />}
                // onClick={() =>
                //   navigate(routes.getEditSupportParameter, {
                //     state: {
                //       action: "edit",
                //       data: {
                //         contractId: option.contractId,
                //         corporateClient: option.corporateClientId,
                //       },
                //     },
                //   })
                // }
                onClick={() => alert("En desarrollo")}
              >
                Editar
              </Menu.Item>
              <Menu.Item leftSection={<IconDetails />} onClick={() => alert("En desarrollo")}>
                Ver detalle
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Nombre</Table.Th>
            <Table.Th>Insumos asociados</Table.Th>
            <Table.Th ta={"center"}>Última actualización</Table.Th>
            <Table.Th ta={"center"}>Estado</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template title="Manual tarifario">
      {FiltersHeader()}
      {renderTable()}

      <AppPagination
        total={supportList?.rowTotal || 0}
        value={filters.page || 1}
        onChange={value => setFilters(state => ({ ...state, page: value }))}
      />
    </Template>
  );
};

export default TariffManual;
