import { Box } from "@mantine/core";
import { Template } from "./Template";

interface TemplateProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  goBackButton: () => void;
  url: string;
  urlType: "base64" | "url";
}

const DownloadTemplate = ({
  title,
  goBackButton,
  children,
  url,
  urlType,
  ...rest
}: TemplateProps) => {
  const iframeValue = urlType === "url" ? url : `data:application/pdf;base64,${url}`;

  return (
    <Box className="main-container" w={"100%"} bg={"#e6f8fa80"} {...rest}>
      <Template title={title} goBackButton={goBackButton} className="full">
        <Box className={`full flex gap-3   ${!children && "justify-center"}`} pt={"md"}>
          <Box w={children ? "70%" : "80%"} px={children ? "" : "lg"}>
            {url && (
              <iframe
                title="document"
                src={iframeValue}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
              />
            )}
          </Box>
          {children && <Box w={"30%"}>{children}</Box>}
        </Box>
      </Template>
    </Box>
  );
};

export default DownloadTemplate;
