import * as MC from "@mantine/core";
import * as TI from "@tabler/icons-react";
import { calculatePagination } from "../utils/utils";

const SkipPrevIcon = () => <TI.IconPlayerSkipBackFilled style={{ width: 12, height: 12 }} />;
const PrevIcon = () => <TI.IconCaretLeftFilled style={{ width: 15, height: 15 }} />;
const NextIcon = () => <TI.IconCaretRightFilled style={{ width: 15, height: 15 }} />;
const SkipNextIcon = () => <TI.IconPlayerSkipForwardFilled style={{ width: 12, height: 12 }} />;

interface IAppPaginationProps extends Omit<MC.PaginationProps, "total"> {
  chunkedTotal?: number;
  total?: number;
  withDetails?: boolean;
  rowTotal?: number;
}

export default function AppPagination(props: IAppPaginationProps) {
  const theme = MC.useMantineTheme();

  const total = calculatePagination(props.total || 0);
  const chunkedTotal = props.chunkedTotal;

  const renderComponent = () => {
    return (
      <MC.Group
        w="100%"
        py={"xl"}
        align="center"
        justify={props.withDetails ? "space-between" : "flex-end"}
        mt="lg"
      >
        {props.withDetails && (
          <MC.Text>
            Pág. {Number(props?.value)} de {chunkedTotal ?? total}{" "}
            {props.rowTotal ? `(${props.rowTotal} encontrados)` : ""}
          </MC.Text>
        )}
        <MC.Pagination.Root
          total={chunkedTotal ?? total}
          value={props.value}
          onChange={page => {
            props.onChange?.(page);
          }}
          onFirstPage={() => props.onChange?.(1)}
          onLastPage={() => props.onChange?.(total)}
          onNextPage={() => props.onChange?.(Number(props?.value) + 1)}
          onPreviousPage={() => props.onChange?.(Number(props?.value) - 1)}
          size={"sm"}
        >
          <MC.Group gap={5} justify="center">
            <MC.Pagination.First
              icon={SkipPrevIcon as React.FC}
              style={{
                backgroundColor: "#00b4cc",
                color: theme.white,
              }}
            />
            <MC.Pagination.Previous
              icon={PrevIcon as React.FC}
              style={{
                backgroundColor: "#00b4cc",
                color: theme.white,
              }}
            />
            <MC.Pagination.Items />
            <MC.Pagination.Next
              icon={NextIcon as React.FC}
              style={{
                backgroundColor: "#00b4cc",
                color: theme.white,
              }}
            />
            <MC.Pagination.Last
              icon={SkipNextIcon as React.FC}
              style={{
                backgroundColor: "#00b4cc",
                color: theme.white,
              }}
            />
          </MC.Group>
        </MC.Pagination.Root>
      </MC.Group>
    );
  };

  return renderComponent();
}
