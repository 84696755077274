/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useQuery } from "@tanstack/react-query";
import { tariffManualListService } from "./tariffManual";
import { SupportParamsFilter } from "./types";

export function useGetTariffManual(
  filters: SupportParamsFilter & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["supportParams", filters.corporateClient, filters.contractId, filters.trigger],
    queryFn: async () => await tariffManualListService(filters, token),
  });

  return query;
}
// export function useCreateSupport(onSuccess = async () => {}) {
//   const token = useAuthentication(state => state.auth.dataBiowel.token);
//   const mutation = useMutation({
//     mutationFn: async (payload: SupportParamsPayload) => await createSupportService(payload, token),
//     onSuccess: async res => {
//       successNotification(res.message, "Realizado con éxito");
//       await onSuccess();
//     },
//     onError: async (error: any) => {
//       warningNotification(error.response.data.error, "Intenta de nuevo");
//     },
//   });

//   return mutation;
// }
// export function useUpdateSupport(onSuccess = async () => {}) {
//   const token = useAuthentication(state => state.auth.dataBiowel.token);
//   const mutation = useMutation({
//     mutationFn: async (payload: SupportUpdatePayload) => await updateSupportService(payload, token),
//     onSuccess: async res => {
//       successNotification(res.message, "Realizado con éxito");
//       await onSuccess();
//     },
//     onError: async (error: any) => {
//       warningNotification(error.response.data.error, "Intenta de nuevo");
//     },
//   });

//   return mutation;
// }

// export function useGetCommentSupports(id: number, enabled: boolean) {
//   const token = useAuthentication(state => state.auth.dataBiowel.token);

//   const query = useQuery({
//     enabled,
//     gcTime: 0,
//     queryKey: ["supportParams", id],
//     queryFn: async () => await commentsSupportService(id, token),
//   });

//   return query;
// }

// export function usePostComment(onSuccess = async () => {}) {
//   const token = useAuthentication(state => state.auth.dataBiowel.token);
//   const mutation = useMutation({
//     mutationFn: async (payload: { rdsId: number; comment: string }) =>
//       await postCommentSupport(payload, token),
//     onSuccess: async () => {
//       successNotification("Comentario enviado con éxito.", "Realizado con éxito");
//       await onSuccess();
//     },
//     onError: async (error: any) => {
//       warningNotification(error.response.data.message, "Intenta de nuevo");
//     },
//   });

//   return mutation;
// }
