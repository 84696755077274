import AppPagination from "@app/common/components/AppPagination";
import { Template } from "@app/common/components/Template";
import { TextField } from "@app/common/components/TextField";
import { IFiltersPagination } from "@app/common/types/http";
import { formattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import routes from "@app/config/routes";

import {
  useGetCompanyClientList,
  useGetContractList,
  useGetStatusList,
} from "@app/services/common/hooks";

import { useGetSupportConsultList } from "@app/services/SupportsConsult/hooks";
import { SupportConsultFilter } from "@app/services/SupportsConsult/types";
import { ActionIcon, Grid, Select, Table, Text, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconDownload, IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const PharmacyControl = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & SupportConsultFilter>(
    {
      contractId: null,
      corporateClient: null,
      startDate: "",
      endDate: "",
      search: "",
      status: null,
      page: 1,
    },
    500,
  );

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSupportConsultList({
    ...filters,
    perpage: 10,
  });

  const {
    data: companyList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyClientList({
    status: "enabled",
    eaccount: GCidAccount(),
  });

  const {
    data: contractList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetContractList(
    {
      corporateClient: Number(filters.corporateClient),
      slim: 1,
    },
    filters.corporateClient !== null,
  );

  const {
    data: statusList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetStatusList({
    staFunctionality: "dispensary_supports_reviews",
  });

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={2}>
          <Select
            data-testid="company"
            label="Sede"
            data={formattedDataSelect(companyList.results, "name", "id")}
            searchable
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                corporateClient: value ?? "",
                contractId: "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            data-testid="contract"
            label="Centro de consumo"
            searchable
            data={formattedDataSelect(contractList.results, "contractName", "id")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                contractId: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            data-testid="status"
            label="Modalidad"
            searchable
            data={formattedDataSelect(statusList.results, "description", "id")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                status: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            data-testid="startDate"
            label="Fecha desde"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                startDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            data-testid="endDate"
            label="Fecha hasta"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                endDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2} className="flex items-center gap-2">
          <TextField
            placeholder="Escribe aquí para buscar..."
            variant="filled"
            endIcon={<IconSearch className="pointer" />}
            className="flex-grow-1 me-4"
            name="search"
            onChange={({ target }) =>
              setFilters(state => ({
                ...state,
                search: target.value,
              }))
            }
          />
          <ActionIcon variant="subtle">
            <IconDownload />
          </ActionIcon>
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof supportList.results)[number]) => {
    return (
      <Table.Tr key={option?.disId}>
        <Table.Td>{option?.patient}</Table.Td>

        <Table.Td>{option?.patientDocument}</Table.Td>

        <Table.Td ta={"start"}>{option?.contract}</Table.Td>
        <Table.Td ta={"start"}>{option?.patientDocument}</Table.Td>
        <Table.Td ta={"start"}>{option?.contract}</Table.Td>
        <Table.Td ta={"center"}>---</Table.Td>
        <Table.Td ta={"center"}>{option?.supports}</Table.Td>
        <Table.Td ta={"center"}>{option?.supports}</Table.Td>
        <Table.Td ta={"center"}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            --
          </Text>
        </Table.Td>
        <Table.Td ta="end">
          <ActionIcon
            variant="subtle"
            onClick={() => {
              navigate(routes.getPharmacyControlDetail, {
                state: { action: "detail", orderId: 3564 },
              });
            }}
          >
            <IconSearch />
          </ActionIcon>
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Paciente</Table.Th>
            <Table.Th ta={"start"}>Identificación</Table.Th>
            <Table.Th ta={"start"}>Sede</Table.Th>
            <Table.Th ta={"start"}>Medicamento</Table.Th>
            <Table.Th ta={"center"}>Cantidad</Table.Th>
            <Table.Th ta={"center"}>F. entrega</Table.Th>
            <Table.Th ta={"center"}>Modalidad</Table.Th>
            <Table.Th ta={"center"}>Entregado por</Table.Th>
            <Table.Th ta={"center"}>Recibido por</Table.Th>
            <Table.Th> </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template title="Control de farmacia">
      {FiltersHeader()}
      {renderTable()}

      <AppPagination
        total={supportList?.rowTotal || 0}
        value={filters.page || 1}
        onChange={value => setFilters(state => ({ ...state, page: value }))}
      />
    </Template>
  );
};

export default PharmacyControl;
