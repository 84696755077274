import { formattedDataSelect } from "@app/common/utils/utils";
import { useGetDocumentTypes } from "@app/services/common/hooks";
import { useRequestDeliveryFull } from "@app/services/deliveryAuth/hooks";
import { RequestFullDeliveryPayload } from "@app/services/deliveryAuth/types";
import { OrderInfo } from "@app/services/drugDelivery/types";
import { Box, Button, Drawer, Select, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";

const FullDelivery = ({
  isOpen,
  onClose,
  dataPatient,
}: {
  isOpen: boolean;
  dataPatient?: OrderInfo;
  onClose: () => void;
}) => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      rcdReason: "",
      rcdPatientName: "",
      rcdPatientDocument: "",
      rcdPatientDocType: "",
      rcdPatientEmail: "",
      rcdPatientPhone: "",
    },

    validate: {
      rcdReason: value => (value !== "" ? null : "Campo obligatorio"),
      rcdPatientName: value =>
        /^[a-zA-Z\s]{2,}$/.test(value.trim())
          ? null
          : "Este campo solo debe contener letras y al menos 2 caracteres",
      rcdPatientDocument: value =>
        /^[a-zA-Z0-9]{4,}$/.test(value) ? null : "Este campo requiere al menos 4 caracteres",
      rcdPatientDocType: value => (value !== "" ? null : "Campo obligatorio"),
      rcdPatientEmail: value => (/^\S+@\S+$/.test(value) ? null : "Correo invalido"),
      rcdPatientPhone: value =>
        /^[0-9]+$/.test(value) ? null : "Debe contener solo caracteres numéricos",
    },
  });

  const {
    data: documentType = {
      results: [],
      rowTotal: 0,
    },
  } = useGetDocumentTypes();

  const closeDrawer = () => {
    form.setValues({
      rcdReason: "",
      rcdPatientName: "",
      rcdPatientDocument: "",
      rcdPatientDocType: "",
      rcdPatientEmail: "",
      rcdPatientPhone: "",
    });
    onClose();
  };

  const createDeliveryRequest = useRequestDeliveryFull(async () => {
    const doAfter = () => {
      closeDrawer();
    };

    await doAfter();
  });

  const onSubmit = async (value: RequestFullDeliveryPayload) => {
    await createDeliveryRequest.mutateAsync(value);
  };

  useEffect(() => {
    if (dataPatient?.patient && dataPatient?.orderId) {
      form.setValues({
        rcdPatientName: dataPatient.patient?.name,
        rcdPatientDocument: dataPatient.patient?.document,
        rcdPatientDocType: formattedDataSelect(documentType.results, "description", "id").find(
          x => x.label === dataPatient.patient?.documentType,
        )?.value,
        rcdPatientEmail: dataPatient.patient?.email,
        rcdPatientPhone: dataPatient.patient?.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPatient?.patient, isOpen, documentType.results, dataPatient?.orderId]);

  return (
    <Drawer
      opened={isOpen}
      onClose={closeDrawer}
      title={"Solicitud de entrega completa"}
      size={"xs"}
    >
      <form
        onSubmit={form.onSubmit(value =>
          onSubmit({
            ...value,
            cluId: Number(dataPatient?.patient.id),
            morId: Number(dataPatient?.orderId),
          }),
        )}
        className="full"
      >
        <Box className="flex flex-column justify-between" h={"100%"}>
          <Box w={"100%"}>
            <TextInput
              label={"Nombre"}
              type="text"
              data-testid="namePatient"
              placeholder="Escribir..."
              key={form.key("rcdPatientName")}
              {...form.getInputProps("rcdPatientName")}
            />

            <Select
              label="Tipo de identificación"
              data={formattedDataSelect(documentType.results, "description", "id")}
              data-testid="docType"
              placeholder="Seleccionar..."
              key={form.key("rcdPatientDocType")}
              {...form.getInputProps("rcdPatientDocType")}
            />

            <TextInput
              type="text"
              label="No. Identificación"
              data-testid="idPatient"
              placeholder="Escribir..."
              key={form.key("rcdPatientDocument")}
              {...form.getInputProps("rcdPatientDocument")}
            />

            <TextInput
              data-testid="insurance"
              label="Aseguradora"
              readOnly
              placeholder="Escribir..."
              value={dataPatient?.contract}
            />
            <TextInput
              data-testid="phonePatient"
              label="Celular"
              type="text"
              placeholder="Escribir..."
              key={form.key("rcdPatientPhone")}
              {...form.getInputProps("rcdPatientPhone")}
            />

            <TextInput
              type="email"
              label="Correo electrónico"
              data-testid="emailPatient"
              key={form.key("rcdPatientEmail")}
              {...form.getInputProps("rcdPatientEmail")}
              placeholder="Escribir..."
            />

            <Textarea
              label="Motivo de solicitud"
              data-testid="requestReason"
              placeholder="Escribir..."
              key={form.key("rcdReason")}
              {...form.getInputProps("rcdReason")}
            />
          </Box>
          <Box className="flex justify-end gap-3">
            <Button variant="outline" onClick={closeDrawer} data-testid="cancelFullRequest">
              Cancelar
            </Button>
            <Button variant="filled" type="submit" data-testid="onSubmitFullRequest">
              {" "}
              Enviar
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};

export default FullDelivery;
