/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { successNotification } from "@app/common/components/notifications";
import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  handleUpdateSupportFileService,
  supportConsultListService,
  supportDetailDispensaryService,
  updateSupportFileService,
} from "./supportsConsult";
import { SupportConsultFilter, UpdSupportFile } from "./types";

export function useGetSupportConsultList(
  filters: SupportConsultFilter & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: [
      "supportParams",
      filters.corporateClient,
      filters.contractId,
      filters.startDate,
      filters.endDate,
      filters.search,
      filters.status,
      filters.page,
    ],
    queryFn: async () => await supportConsultListService(filters, token),
  });

  return query;
}

export function useGetSupportDetailDispensary(
  filters: { id: number; trigger: number },
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["supportParams", filters.id, filters.trigger],
    queryFn: async () => await supportDetailDispensaryService(filters, token),
  });

  return query;
}

export function useUpdateSupportFile(onSuccess = async () => {}) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: UpdSupportFile) =>
      (await payload.id)
        ? handleUpdateSupportFileService(payload, token)
        : updateSupportFileService(payload, token),
    onSuccess: async data => {
      successNotification(data.message, "Actualizado exitosamente");
      await onSuccess();
    },
  });

  return mutation;
}
