import { Box, Button, Modal, Textarea } from "@mantine/core";

import { useState } from "react";

export default function JustificationModal({
  isOpen,
  onClose,
  handleChange,
}: {
  isOpen: boolean;
  handleChange: (data: string) => void;
  onClose: () => void;
}) {
  const [justification, setJustification] = useState("");

  const closeModal = () => {
    setJustification("");
    onClose();
  };

  const handleSubmit = () => {
    handleChange(justification);
  };

  return (
    <Modal w="45%" size={450} title="Justificación" opened={isOpen} onClose={closeModal}>
      <Box className="flex flex-column justify-center" mb={"sm"}>
        <Textarea
          placeholder="Escribir..."
          autoFocus
          data-testid="justificationReason"
          value={justification}
          onChange={({ target }) => {
            setJustification(target.value);
          }}
          rows={5}
        />
      </Box>
      <Box className="flex justify-end gap-3" pt={"sm"}>
        <Button variant="outline" data-testid="rejectJustification" onClick={closeModal}>
          Cancelar
        </Button>
        <Button variant="filled" data-testid="approvedJustification" onClick={handleSubmit}>
          Aceptar
        </Button>
      </Box>
    </Modal>
  );
}
