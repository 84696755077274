import DrawerNotes from "@app/common/components/DrawerNotes";
import JustificationModal from "@app/common/components/JustificationModal";
import { confirmNotification, warningNotification } from "@app/common/components/notifications";
import { Template } from "@app/common/components/Template";
import routes from "@app/config/routes";
import {
  useGetSupportDetailDispensary,
  useUpdateSupportFile,
} from "@app/services/SupportsConsult/hooks";
import { FileSupportDispensary } from "@app/services/SupportsConsult/types";
import { calculateAge } from "@app/utils/generateId";
import { ActionIcon, Badge, Box, Button, Card, Grid, Menu, Table, Text } from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleX,
  IconDotsVertical,
  IconTrendingUp3,
} from "@tabler/icons-react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DetailDispensarySupportFiles = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [trigger, setTrigger] = useState(0);
  const [openDrawer, setOpenDrawer] = useState({
    isOpen: false,
  });

  const [documentSelected, setDocumentSelected] = useState<{
    url: string | null;
    id: number | null;
  }>({
    url: null,
    id: null,
  });
  const { data: detailSupport } = useGetSupportDetailDispensary({
    id: location?.state?.id,
    trigger: trigger,
  });
  const [justificationModal, setJustificationModal] = useState<{
    isOpen: boolean;
    id: number | null;
  }>({
    isOpen: false,
    id: null,
  });

  const updateStatusSupport = async (approved: 1 | 0, id: number, value?: string) => {
    if (approved === 1) {
      await updateAuth.mutateAsync({
        approved,
        id: id,
      });
    } else {
      await updateAuth.mutateAsync({
        approved,
        id: id,
        comment: value ?? "",
      });
    }
  };

  const handleRejectAuth = (value: string) => {
    if (justificationModal.id) {
      updateStatusSupport(0, justificationModal.id, value);
    }
  };

  const updateAuth = useUpdateSupportFile(async () => {
    const doAfter = () => {
      setTrigger(trigger + 1);
      setJustificationModal({
        isOpen: false,
        id: null,
      });
    };

    await doAfter();
  });

  const renderRow = (option: FileSupportDispensary) => {
    const isSelected = option.rdsId === documentSelected.id;
    const date = option?.createdAt?.split("T")[0];
    const time = option?.createdAt?.split("T")[1];
    return (
      <Table.Tr
        key={option.rdsId}
        bg={isSelected ? "rgba(253, 126, 20, 0.1)" : ""}
        className="pointer"
        onClick={() => {
          setDocumentSelected(state => ({ ...state, url: option.url, id: option.rdsId }));
        }}
      >
        <Table.Td>{option.supportName}</Table.Td>
        <Table.Td ta={"center"}>
          {date} {time}
        </Table.Td>
        <Table.Td ta={"center"}>
          <Badge variant="light" color={option.status.fontColor} w={"100%"}>
            {option.status.description}
          </Badge>
        </Table.Td>
        <Table.Td ta={"end"} className="flex gap-2 " pl={"md"}>
          {(option.status.value === "pending" || option.status.value === "reject") && (
            <Box
              className={`message-icon  flex justify-center items-center`}
              mt={4}
              onClick={() => setOpenDrawer({ isOpen: true })}
            >
              <Box pt={2}>{option.numberComments}</Box>
            </Box>
          )}
          {option.status.value === "pending" && (
            <Menu
              position="bottom-end"
              withArrow
              data-testid="menu"
              zIndex={1000}
              trigger="hover"
              openDelay={100}
              closeDelay={400}
            >
              <Menu.Target>
                <ActionIcon variant="subtle">
                  <IconDotsVertical />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<IconCircleCheck color="#7bc62d" />}
                  data-testid="validSupport"
                  onClick={() =>
                    confirmNotification(
                      `Se aprobará el soporte: ${option.supportName}`,
                      "¿Está seguro?",
                      () => {
                        updateStatusSupport(1, option.rdsId);
                      },
                    )
                  }
                >
                  Aprobar
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconCircleX color="#ff4500" />}
                  data-testid="rejectSupport"
                  onClick={() =>
                    confirmNotification(
                      `Se rechazará el soporte: ${option.supportName}`,
                      "¿Está seguro?",
                      () => {
                        setJustificationModal({ id: option.rdsId, isOpen: true });
                      },
                    )
                  }
                >
                  Rechazar
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table mt={"sm"} mb="sm" className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Documento</Table.Th>
            <Table.Th ta={"center"}>Fecha adjunto</Table.Th>
            <Table.Th ta={"center"}>Estado</Table.Th>
            <Table.Th ta={"end"}></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {detailSupport?.results?.files && detailSupport?.results?.files?.length > 0 ? (
            detailSupport?.results?.files?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template
      title="Detalle documentos"
      goBackButton={() => {
        navigate(routes.getDispensarySupportFiles);
      }}
    >
      <JustificationModal
        isOpen={justificationModal.isOpen}
        handleChange={handleRejectAuth}
        onClose={() => {
          setJustificationModal({
            isOpen: false,
            id: null,
          });
        }}
      />
      <DrawerNotes
        id={Number(documentSelected.id)}
        isOpen={openDrawer.isOpen}
        trigger={trigger}
        setTrigger={setTrigger}
        onClose={() => setOpenDrawer({ isOpen: false })}
      />
      <Box>
        <Text fz="md" ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Información del paciente
        </Text>
        <Card mb={"md"} padding="lg" bg={"#e1faff71"} radius="md" __size="xl" className="">
          <Grid>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Nombre paciente
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.patient?.name ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Identificación
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.patient?.documentType}
                {detailSupport?.results?.patient?.document ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Edad
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {calculateAge(detailSupport?.results?.patient?.birthDate ?? "No hay información")}{" "}
                años
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Empresa cliente
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.corporateClient ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Contrato
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.contract ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Nivel
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.level ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Tipo de afiliado
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.patient?.affiliationType ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Población
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.population ?? "No hay información"}
              </Text>
            </Grid.Col>
          </Grid>
        </Card>
      </Box>
      <Box h={"80%"}>
        {documentSelected.url && (
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"} py={"md"}>
            Registrado por: "No hay información" | Última modificación: "No hay información"
          </Text>
        )}
        <Grid h={"95%"} className="flex ">
          <Grid.Col span={6} className="flex justify-center" h={"100%"}>
            {documentSelected.url ? (
              <iframe
                title="document"
                src={documentSelected.url}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
              />
            ) : (
              <Box className="flex  items-center" h={"100%"}>
                <Text fz="h4" ml="xs" flex={1} fw={"inherit"} py={"md"} className="flex gap-2">
                  Seleccione un documento para visualizarlo
                  <IconTrendingUp3 style={{ width: 30, height: 30 }} />
                </Text>
              </Box>
            )}
          </Grid.Col>
          <Grid.Col span={6} pl={"md"}>
            {renderTable()}
            <Box className="flex justify-end gap-3">
              <Button
                variant="outline"
                data-testid="gobackCancelButton"
                onClick={() => navigate(routes.getDispensarySupportFiles)}
              >
                Cancelar
              </Button>
              <Button
                variant="filled"
                data-testid="approvedJustification"
                onClick={() => warningNotification("Funcionalidad en desarrollo")}
              >
                Guardar
              </Button>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </Template>
  );
};

export default DetailDispensarySupportFiles;
