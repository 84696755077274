import { useGetCommentSupports, usePostComment } from "@app/services/supportParams/hooks";
import { useAuthentication } from "@app/store/useAuthtentication";
import { Box, Button, Drawer, Textarea } from "@mantine/core";
import { IconSend2 } from "@tabler/icons-react";
import { useState } from "react";
import { warningNotification } from "./notifications";

const DrawerNotes = ({
  isOpen,
  onClose,
  id,
  setTrigger,
  trigger,
}: {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  trigger: number;
  setTrigger: (state: number) => void;
}) => {
  const { fullNameUser } = useAuthentication(state => state.auth.dataBiowel);
  const [comment, setComment] = useState("");

  const {
    data: notesList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCommentSupports(id, isOpen && id ? true : false);

  const closeDrawer = () => {
    onClose();
  };

  const postComment = usePostComment(async () => {
    const doAfter = () => {
      closeDrawer();
      setTrigger(trigger + 1);
    };

    await doAfter();
  });

  const onSubmit = async () => {
    if (comment.length > 0) {
      if (comment.length > 3 && comment.length < 300) {
        await postComment.mutateAsync({ rdsId: id, comment });
      } else {
        warningNotification(
          "El comentario debe tener una longitud mínima de 3 caracteres máxima de 300.",
          "Intenta de nuevo",
        );
      }
    } else {
      warningNotification("Es obligatorio enviar un comentario", "Intenta de nuevo");
    }
  };

  return (
    <Drawer opened={isOpen} onClose={closeDrawer} title={"Seguimiento pendiente"} size={"xs"}>
      <Box className="flex flex-column justify-between" h={"100%"}>
        <Box w={"100%"} h={"80%"} className="overflow-y-auto" py={"sm"}>
          {notesList.results.map(note => {
            return (
              <Box>
                <span className="secondary-color"> {note.createdBy}</span>
                <p>{note.comment}</p>
                <span className="secondary-color flex justify-end">
                  {" "}
                  {note.createdAt.replace("T", " ")}
                </span>

                <hr />
              </Box>
            );
          })}
        </Box>
        <Box w={"100%"} className="cardSearch">
          <Textarea
            label={fullNameUser}
            data-testid="requestReason"
            onChange={e => {
              setComment(e.target.value);
            }}
            placeholder="Escribir..."
            rows={4}
          />
          <Box className="flex justify-end ">
            <Button
              variant="transparent"
              p={0}
              type="submit"
              data-testid="onSubmitFullRequest"
              onClick={onSubmit}
            >
              {" "}
              <IconSend2 />
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerNotes;
