import AppPagination from "@app/common/components/AppPagination";
import { Template } from "@app/common/components/Template";
import { IFiltersPagination } from "@app/common/types/http";
import { formattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import routes from "@app/config/routes";

import { useGetCompanyClientList, useGetContractList } from "@app/services/common/hooks";
import { useGetSupportParamsList } from "@app/services/supportParams/hooks";
import { SupportParamsFilter } from "@app/services/supportParams/types";
import { ActionIcon, Button, Grid, Menu, Select, Table } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconCirclePlus,
  IconCopy,
  IconDetails,
  IconDotsVertical,
  IconPencil,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const SupportsParameters = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & SupportParamsFilter>(
    { contractId: null, corporateClient: null },
    500,
  );

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSupportParamsList({
    ...filters,
    perpage: 10,
  });

  const {
    data: companyList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyClientList({
    status: "enabled",
    eaccount: GCidAccount(),
  });

  const {
    data: contractList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetContractList(
    {
      corporateClient: Number(filters.corporateClient),
      slim: 1,
    },
    filters.corporateClient !== null,
  );

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={3}>
          <Select
            data-testid="company"
            label="Empresa / Cliente"
            data={formattedDataSelect(companyList.results, "name", "id")}
            searchable
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                corporateClient: value ?? "",
                contractId: "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            data-testid="contract"
            label="Contrato"
            searchable
            data={formattedDataSelect(contractList.results, "contractName", "id")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                contractId: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={6} className="flex justify-end">
          <Button
            variant="subtle"
            rightSection={<IconCirclePlus />}
            onClick={() =>
              navigate(routes.getCreateSupportParameter, {
                state: { action: "create" },
              })
            }
          >
            Crear parametrización
          </Button>
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof supportList.results)[number]) => {
    const date = (option?.createdAt ?? "").split("T")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={option?.corporateClientId}>
        <Table.Td>{option?.corporateClientName}</Table.Td>

        <Table.Td>{option?.contractName}</Table.Td>

        <Table.Td ta={"center"}>{option?.supports?.length}</Table.Td>
        <Table.Td ta={"start"}>{option?.createdBy}</Table.Td>
        <Table.Td ta={"center"}>{date}</Table.Td>
        <Table.Td ta="end">
          <Menu position="bottom-end" withArrow>
            <Menu.Target>
              <ActionIcon variant="subtle">
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconPencil />}
                onClick={() =>
                  navigate(routes.getEditSupportParameter, {
                    state: {
                      action: "edit",
                      data: {
                        contractId: option.contractId,
                        corporateClient: option.corporateClientId,
                      },
                    },
                  })
                }
              >
                Editar
              </Menu.Item>
              <Menu.Item leftSection={<IconCopy />} onClick={() => alert("En desarrollo")}>
                Duplicar
              </Menu.Item>
              <Menu.Item
                leftSection={<IconDetails />}
                onClick={() =>
                  navigate(routes.getEditSupportParameter, {
                    state: {
                      action: "edit",
                      data: {
                        contractId: option.contractId,
                        corporateClient: option.corporateClientId,
                      },
                    },
                  })
                }
              >
                Ver detalle
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Empresa/Cliente</Table.Th>
            <Table.Th>Contrato</Table.Th>
            <Table.Th ta={"center"}>Documentos</Table.Th>
            <Table.Th ta={"start"}>Registrado por</Table.Th>
            <Table.Th ta={"center"}>Fecha</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template title="Parametrización de soportes">
      {FiltersHeader()}
      {renderTable()}

      <AppPagination
        total={supportList?.rowTotal || 0}
        value={filters.page || 1}
        onChange={value => setFilters(state => ({ ...state, page: value }))}
      />
    </Template>
  );
};

export default SupportsParameters;
