import * as MC from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconChevronDown } from "@tabler/icons-react";
import React from "react";

const primaryColor: MC.MantineColorsTuple = [
  "#e1faff",
  "#cbefff",
  "#9adcff",
  "#65c9fe",
  "#3bb8fd",
  "#22aefd",
  "#0aa8fe",
  "#00b4cc",
  "#0093e3",
  "#00b4cc",
  "#1a538d",
  "#1a538d",
  "#E6F8FA",
];

const secondaryColor: MC.MantineColorsTuple = [
  "#e7f6ff",
  "#d0e7ff",
  "#9fccfc",
  "#6ab0fb",
  "#4398fa",
  "#2c88fa",
  "#1a538d",
  "#2081fb",
  "#126fe1",
  "#1a538d",
  "#1a538d",
];

const componentsTheme: MC.MantineThemeComponents = {
  Button: MC.Button.extend({
    defaultProps: {
      px: 12,
      fz: "sm",
      size: "xs",
    },
  }),
  Badge: MC.Badge.extend({
    styles: theme => ({
      label: { textTransform: "none", fontSize: theme.fontSizes.sm },
      root: {
        paddingLeft: theme.spacing.SM,
        paddingRight: theme.spacing.SM,
      },
    }),
  }),
  TextInput: MC.TextInput.extend({
    defaultProps: {
      leftSectionPointerEvents: "none",
      rightSectionPointerEvents: "none",
      size: "xs",
    },
    styles: theme => ({
      label: {
        paddingLeft: MC.rem(3),
        color: theme.colors["brand-color"][10],
        paddingTop: " 5px",
      },
    }),
  }),
  DatePickerInput: DatePickerInput.extend({
    defaultProps: {
      leftSectionPointerEvents: "none",
      rightSectionPointerEvents: "none",
      popoverProps: { shadow: "md" },
      size: "xs",
    },
  }),
  NumberInput: MC.NumberInput.extend({
    defaultProps: {
      leftSectionPointerEvents: "none",
      rightSectionPointerEvents: "none",
      hideControls: true,
      size: "xs",
    },
    styles: theme => ({
      label: {
        paddingLeft: MC.rem(3),
        color: theme.colors["brand-color"][10],
      },
    }),
  }),
  Combobox: MC.Combobox.extend({
    defaultProps: {
      transitionProps: { transition: "fade", duration: 100 },
      shadow: "md",
      size: "xs",
    },
  }),
  Select: MC.Select.extend({
    defaultProps: {
      withCheckIcon: false,
      nothingFoundMessage: "No hay datos",
      size: "xs",
      placeholder: "Seleccionar...",
      rightSection: React.createElement(IconChevronDown),
    },
    styles: theme => ({
      label: {
        color: theme.colors["brand-color"][11],
        paddingLeft: MC.rem(3),
        paddingTop: " 5px",
      },
    }),
  }),
  Textarea: MC.Textarea.extend({
    defaultProps: {
      size: "xs",
      placeholder: "Seleccionar...",
    },
    styles: theme => ({
      label: {
        color: theme.colors["brand-color"][11],
        paddingLeft: MC.rem(3),
        paddingTop: " 5px",
      },
    }),
  }),
  MultiSelect: MC.MultiSelect.extend({
    defaultProps: {
      checkIconPosition: "right",
      nothingFoundMessage: "No hay datos",
      size: "xs",
      placeholder: "Seleccionar...",
      rightSection: React.createElement(IconChevronDown),
      hidePickedOptions: true,
    },
    styles: theme => ({
      label: {
        color: theme.colors["brand-color"][11],
        paddingLeft: MC.rem(3),
        paddingTop: " 5px",
      },
      pillsList: {
        flexWrap: "nowrap",
        overflowX: "auto",
      },
    }),
  }),
  Checkbox: MC.Checkbox.extend({
    defaultProps: { size: "xs" },
    styles: theme => ({
      label: {
        color: theme.colors["brand-color"][11],
        paddingLeft: MC.rem(3),
      },
    }),
  }),
  Tooltip: MC.Tooltip.extend({
    styles: theme => ({
      tooltip: {
        color: theme.colors["brand-color"][11],
        backgroundColor: "#fff",
        boxShadow: "0px 0px 60px 60px rgba(136,145,200,0.1)",
      },
    }),
  }),
  Radio: MC.Radio.extend({
    defaultProps: { size: "xs" },
  }),
  Menu: MC.Menu.extend({
    defaultProps: {
      shadow: "md",
      radius: "md",
    },
    styles: theme => ({
      itemSection: {
        width: MC.rem(16),
      },
      item: {
        padding: `${MC.rem(4)} ${MC.rem(8)}`,
        fontSize: theme.fontSizes.xs,
      },
    }),
  }),
  Modal: MC.Modal.extend({
    defaultProps: {
      centered: true,
    },
    styles: theme => ({
      title: {
        fontWeight: 700,
        fontSize: theme.headings.sizes.h2.fontSize,
        color: theme.colors["brand-color"][9],
        marginInlineStart: "auto",
        marginInlineEnd: "auto",
      },
      body: {
        padding: theme.spacing.lg,
      },
      close: {
        position: "absolute",
        right: 11,
      },
      header: {
        borderBottom: `2px solid ${theme.colors.gray[3]}`,
      },
    }),
  }),
  Drawer: MC.Drawer.extend({
    defaultProps: {
      position: "right",
      offset: 8,
      radius: "md",
    },
    styles: theme => ({
      title: {
        fontWeight: 700,
        fontSize: theme.headings.sizes.h4.fontSize,
        color: theme.colors["brand-color"][9],
        marginInlineStart: "auto",
        marginInlineEnd: "auto",
        paddingRight: "1rem",
      },
      body: {
        padding: theme.spacing.lg,
        height: "90%",
      },
      close: {
        position: "absolute",
        right: 11,
      },
      header: {
        borderBottom: `2px solid ${theme.colors.gray[3]}`,
      },
    }),
  }),
  Paper: MC.Paper.extend({
    defaultProps: {
      shadow: "md",
      radius: "md",
    },
  }),
  Table: MC.Table.extend({
    defaultProps: {
      highlightOnHover: true,
      striped: false,
      withRowBorders: true,
      bg: "#fff",
    },
    styles: theme => ({
      thead: {
        backgroundColor: theme.colors["brand-color"][12],
        color: theme.colors["brand-color"][9],
      },
    }),
  }),
  PaginationRoot: MC.Pagination.Root.extend({
    defaultProps: {
      color: "brand-color.0",
    },
  }),
  Pagination: MC.Pagination.extend({
    styles: theme => ({
      root: {
        alignSelf: "flex-end",
      },
      control: {
        borderRadius: theme.radius.xl,
        border: 0,
      },
    }),
  }),
  Tabs: MC.Tabs.extend({
    styles: theme => ({
      tab: {
        background: theme.colors.gray[0],
        paddingLeft: theme.spacing.xl,
        paddingRight: theme.spacing.xl,
      },
      tabLabel: {
        color: theme.colors["brand-color"][6],
      },
    }),
  }),
  ActionIcon: MC.ActionIcon.extend({
    defaultProps: {
      radius: "xl",
    },
  }),
};

const theme = MC.createTheme({
  colors: {
    "brand-color": primaryColor,
    "secondary-color": secondaryColor,
  },
  activeClassName: "",
  primaryColor: "brand-color",
  primaryShade: 9,
  fontFamily: "PT Sans , sans-serif",
  fontSizes: {
    xs: MC.rem(13),
    md: MC.rem(14),
    lg: MC.rem(16),
  },
  headings: {
    sizes: {
      h1: { fontSize: MC.rem(28) },
      h2: { fontSize: MC.rem(26) },
      h3: { fontSize: MC.rem(24) },
      h4: { fontSize: MC.rem(22) },
      h5: { fontSize: MC.rem(20) },
      h6: { fontSize: MC.rem(18) },
    },
  },
  components: componentsTheme,
  black: MC.DEFAULT_THEME.colors.gray[6],
});

export const themeResolver: MC.CSSVariablesResolver = theme => ({
  variables: {},
  light: {
    "--mantine-color-secondary-color-filled": theme.colors["secondary-color"][6],
  },
  dark: {
    "--mantine-color-gray-0": theme.colors.dark[8],
    "--mantine-color-gray-1": theme.colors.dark[6],
    "--mantine-color-gray-2": theme.colors.dark[5],
    "--mantine-color-gray-3": theme.colors.dark[4],
  },
});

export default Object.freeze(theme);
