import AppPagination from "@app/common/components/AppPagination";
import { Template } from "@app/common/components/Template";
import { IFiltersPagination } from "@app/common/types/http";
import { formattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";

import { useGetCompanyClientList, useGetContractList } from "@app/services/common/hooks";

import { useGetSupportConsultList } from "@app/services/SupportsConsult/hooks";
import { SupportConsultFilter } from "@app/services/SupportsConsult/types";
import { Grid, Select, Table, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconDownload } from "@tabler/icons-react";

const ConsumerOutReport = () => {
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & SupportConsultFilter>(
    {
      contractId: null,
      corporateClient: null,
      startDate: "",
      endDate: "",
      search: "",
      page: 1,
    },
    500,
  );

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSupportConsultList({
    ...filters,
    perpage: 10,
  });

  const {
    data: companyList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyClientList({
    status: "enabled",
    eaccount: GCidAccount(),
  });

  const {
    data: contractList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetContractList(
    {
      corporateClient: Number(filters.corporateClient),
      slim: 1,
    },
    filters.corporateClient !== null,
  );

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={2}>
          <Select
            data-testid="company"
            label="Almacen"
            data={formattedDataSelect(companyList.results, "name", "id")}
            searchable
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                corporateClient: value ?? "",
                contractId: "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            data-testid="contract"
            label="Centro de consumo"
            searchable
            data={formattedDataSelect(contractList.results, "contractName", "id")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                contractId: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            data-testid="startDate"
            label="Fecha desde"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                startDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            data-testid="endDate"
            label="Fecha hasta"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                endDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={4} ta={"end"} className="flex justify-end">
          <IconDownload />
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof supportList.results)[number]) => {
    const date = (option?.createdAt ?? "").split("T")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={option?.disId}>
        <Table.Td ta={"center"}>{option?.disId}</Table.Td>
        <Table.Td ta={"center"}>{option?.disId}</Table.Td>

        <Table.Td ta={"center"}>{option?.patientDocument}</Table.Td>
        <Table.Td ta={"center"}>{option?.corporateClient}</Table.Td>
        <Table.Td ta={"start"}>{date}</Table.Td>

        <Table.Td ta={"center"}>{option?.contract}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"center"}>Item</Table.Th>
            <Table.Th ta={"center"}>Código</Table.Th>
            <Table.Th ta={"center"}>Lote</Table.Th>
            <Table.Th ta={"center"}>F. vencimiento</Table.Th>
            <Table.Th ta={"start"}>Medicamento</Table.Th>
            <Table.Th ta={"center"}>Cantidad</Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template title="Informe salida para consumo">
      {FiltersHeader()}
      {renderTable()}

      <AppPagination
        total={supportList?.rowTotal || 0}
        value={filters.page || 1}
        onChange={value => setFilters(state => ({ ...state, page: value }))}
      />
    </Template>
  );
};

export default ConsumerOutReport;
