import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import {
  DetailSupportDispensaryResponse,
  SupportConsultFilter,
  SupportConsultResponse,
  UpdSupportFile,
} from "./types";

export async function supportConsultListService(
  filters: SupportConsultFilter & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<SupportConsultResponse[]>>(
    "/dispensarySupports/",
    { params: filters },
  );
  return res.data;
}
export async function supportDetailDispensaryService(filters: { id: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<DetailSupportDispensaryResponse>>(
    `/dispensarySupports/detail/${filters.id}`,
    { params: filters },
  );
  return res.data;
}

export async function handleUpdateSupportFileService(payload: UpdSupportFile, token: string) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.patch<IResponse>(
    `/dispensarySupports/${payload.id}`,
    payload,
  );
  return response.data;
}
export async function postCommentSupport(
  payload: { rdsId: number; comment: string },
  token: string,
) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.post<IResponse>(`/commentsDispensarySupport/`, payload);
  return response.data;
}

export async function updateSupportFileService(payload: UpdSupportFile, token: string) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.put<IResponse>(`/dispensarySupports/`, payload);
  return response.data;
}
