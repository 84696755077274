import { Template } from "@app/common/components/Template";
import { TextField } from "@app/common/components/TextField";
import { UseFormattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import routes from "@app/config/routes";
import { useGetCompanyClientList } from "@app/services/common/hooks";
import { SupportParamsFilter } from "@app/services/supportParams/types";
import { useGetTariffManual } from "@app/services/tariffManual/hooks";
import { Box, Button, Grid, Select, Table, TextInput } from "@mantine/core";
import { IconCircleMinus, IconCirclePlus, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HandleTariffManual = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState<SupportParamsFilter>({
    corporateClient: null,
    contractId: null,
    trigger: 0,
  });
  const action = location?.state?.action ?? "";
  const { contractId, corporateClient } = location?.state?.data ?? {};
  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetTariffManual({
    ...filters,
    perpage: 10,
  });
  // const {
  //   data: supportList = {
  //     results: [],
  //     rowTotal: 0,
  //   },
  // } = useGetSupportParamsList(
  //   {
  //     ...filters,
  //   },
  //   filters.contractId !== null,
  // );

  const {
    data: companyList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyClientList({
    status: "enabled",
    eaccount: GCidAccount(),
  });

  // const {
  //   data: extensionList = {
  //     results: [],
  //     rowTotal: 0,
  //   },
  // } = useGetExtensionList();

  // const {
  //   data: contractList = {
  //     results: [],
  //     rowTotal: 0,
  //   },
  // } = useGetContractList(
  //   {
  //     corporateClient: Number(filters.corporateClient),
  //     slim: 1,
  //   },
  //   filters.corporateClient !== null,
  // );

  // const isDisabledField = (support: Support) => {
  //   if (action === "create") {
  //     const isDisabled = support.id;
  //     return Boolean(isDisabled);
  //   } else {
  //     return support.isEditing ? false : true;
  //   }
  // };

  useEffect(() => {
    if (action === "edit") {
      setFilters({
        contractId,
        corporateClient,
      });
    }
  }, [contractId, corporateClient, action]);

  const filterSection = () => {
    return (
      <>
        <Box className="cardSearch " p={"xl"}>
          <b className="primary-color">● Información general</b>
          <Grid>
            <Grid.Col span={6}>
              {" "}
              <TextInput
                label="Nombre manual"
                placeholder="Escribir..."
                required
                value="Pa la proxima..."
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                data-testid="insurance"
                clearable
                required
                disabled={action === "edit"}
                label="Tipo de código"
                data={UseFormattedDataSelect(companyList.results, "name", "id")}
                searchable
                value={String(filters.corporateClient)}
                placeholder="Seleccionar..."
                onChange={(_, value) => {
                  setFilters(state => ({
                    ...state,
                    corporateClient: value?.value ?? "",
                    contractId: "",
                  }));
                }}
              />
            </Grid.Col>
          </Grid>
        </Box>
        <Grid align="flex-end" mb="lg" w="100%">
          <Grid.Col span={6}>
            <TextField
              placeholder="Escribe aquí para buscar..."
              variant="filled"
              endIcon={<IconSearch className="pointer" />}
              className="flex-grow-1 me-4"
              name="search"
              onChange={({ target }) =>
                setFilters(state => ({
                  ...state,
                  search: target.value,
                }))
              }
            />
          </Grid.Col>

          <Grid.Col span={6} className="flex justify-end">
            <Button
              variant="subtle"
              rightSection={<IconCirclePlus />}
              onClick={() =>
                navigate(routes.getTariffManualCreate, {
                  state: { action: "create" },
                })
              }
            >
              Agregar artículos
            </Button>
          </Grid.Col>
        </Grid>
      </>
    );
  };

  // const createSupportRequest = useCreateSupport(async () => {
  //   const doAfter = () => {
  //     setFilters(state => ({
  //       ...state,
  //       trigger: (state.trigger ?? 0) + 1,
  //     }));
  //   };

  //   await doAfter();
  // });
  // const updateSupportRequest = useUpdateSupport(async () => {
  //   const doAfter = () => {
  //     setFilters(state => ({
  //       ...state,
  //       trigger: (state.trigger ?? 0) + 1,
  //     }));
  //   };

  //   await doAfter();
  // });

  // const onSubmit = async () => {
  //   const list = supportListUpdated.filter(item => item.generateId);
  //   const validationFileName = list.some(item => item.filename === "");
  //   const validationExtensions = list.some(item => item.extensions.length === 0);

  //   if (validationFileName || validationExtensions) {
  //     warningNotification("Verifique que todos los campos se encuentren llenos");
  //   } else {
  //     await createSupportRequest.mutateAsync({
  //       contractId: Number(filters.contractId ?? ""),
  //       supports: list.map(x => ({
  //         ...x,
  //         extensions: x.extensions.map(Number),
  //       })),
  //     });
  //   }
  // };

  // const handleEditFunction = async (support: Support, isEditing: boolean, isDisabled?: string) => {
  //   const editSupport = supportListUpdated.map(item =>
  //     item.id === support.id ? { ...item, isEditing } : item,
  //   );
  //   setSupportListUpdated(editSupport);

  //   if (!isEditing) {
  //     const list = supportListUpdated.find(item => item.id === support.id);
  //     if (list) {
  //       await updateSupportRequest.mutateAsync({
  //         action: isDisabled ?? "",
  //         extensions: valueMultiSelect(support).map(Number),
  //         filename: list.filename,
  //         id: Number(support.id),
  //       });
  //     }
  //   }
  // };

  // const handleCancelFunction = (support: Support) => {
  //   const findSupport: Support = supportList.results
  //     .at(0)
  //     ?.supports.find(item => item.id === support.id) ?? {
  //     filename: "",
  //     extensions: [],
  //   };

  //   const editSupport: Support[] = supportListUpdated.map(item =>
  //     item.id === support.id ? findSupport : item,
  //   );
  //   if (editSupport) {
  //     setSupportListUpdated(editSupport);
  //   }
  // };
  // const supportListSection = () => {
  //   return (
  //     <Box w={"100%"}>
  //       <Title
  //         size={"h3"}
  //         w={"100%"}
  //         c="brand-color"
  //         pb={"sm"}
  //         fw={"bold"}
  //         ta={"start"}
  //         className="flex "
  //       >
  //         <div
  //           data-testid="goback1"
  //           className="flex items-center pointer"
  //           onClick={() => navigate(-1)}
  //         >
  //           <Tooltip label="Regresar" withArrow position="right">
  //             <IconChevronLeft
  //               style={{ height: 30, width: 30 }}
  //               className="primary-color pointer"
  //             />
  //           </Tooltip>
  //         </div>
  //         {action === "create" ? "Crear" : "Editar"} parametrización de soportes
  //       </Title>
  //       <Box py={"xs"}>
  //         <Grid>
  //           <Grid.Col span={10}>
  //             <b className="primary-color">Asesores</b>
  //           </Grid.Col>
  //           <Grid.Col span={2} className="flex justify-end">
  //             {action === "create" && (
  //               <Button
  //                 variant="transparent"
  //                 data-testid="addSupport"
  //                 rightSection={<IconCirclePlus />}
  //                 onClick={addSupport}
  //               >
  //                 Agregar
  //               </Button>
  //             )}
  //           </Grid.Col>
  //         </Grid>
  //       </Box>

  //       {supportListUpdated.length > 0 &&
  //         supportListUpdated.map(support => {
  //           const isDisabled = support.deletedAt !== null ? true : false;
  //           return (
  //             <Card mb={"sm"} key={support.filename} data-testid="holaa">
  //               <Grid>
  //                 {support.id && (
  //                   <>
  //                     <Grid.Col span={10}>
  //                       {isDisabled ? (
  //                         <Box fz={12}>
  //                           <span>
  //                             Inhabilitado por
  //                             {support.deletedBy} | {support.deletedAt}
  //                           </span>
  //                         </Box>
  //                       ) : (
  //                         <Box fz={12}>
  //                           Creación: {support.createdAt} | {support.createdBy}
  //                         </Box>
  //                       )}
  //                     </Grid.Col>
  //                     <Grid.Col span={2} className="flex justify-end">
  //                       <Badge variant="light" color={isDisabled ? "red" : "green"}>
  //                         {isDisabled ? "Inhabilitado" : "Habilitado"}
  //                       </Badge>
  //                     </Grid.Col>
  //                   </>
  //                 )}
  //                 <Grid.Col span={5.5}>
  //                   <TextInput
  //                     label="Documento de cargue asesor"
  //                     placeholder="Escribir..."
  //                     data-testid={support.filename}
  //                     autoFocus
  //                     disabled={isDisabledField(support)}
  //                     defaultValue={support.filename}
  //                     onChange={({ target }) =>
  //                       handleChangeSupport(support, "filename", target.value)
  //                     }
  //                   />
  //                 </Grid.Col>
  //                 <Grid.Col span={5.5}>
  //                   <MultiSelect
  //                     clearable
  //                     data-testid="insurance"
  //                     label="Tipo de documento"
  //                     data={formattedDataSelect(extensionList.results, "extension", "id", false)}
  //                     searchable
  //                     disabled={isDisabledField(support)}
  //                     defaultValue={valueMultiSelect(support)}
  //                     onChange={e => handleChangeSupport(support, "extensions", e)}
  //                   />
  //                 </Grid.Col>
  //                 <Grid.Col span={1}>
  //                   <Box
  //                     className="flex justify-center items-end full"
  //                     p={action === "create" ? "xs" : "0"}
  //                   >
  //                     {action === "create" ? (
  //                       <div
  //                         className="pointer"
  //                         data-testid="deleteSupport"
  //                         onClick={() => {
  //                           deleteSupport(support);
  //                         }}
  //                       >
  //                         {!isDisabledField(support) && <IconTrash />}
  //                       </div>
  //                     ) : support.isEditing ? (
  //                       <Box pb={"0.2rem"} className="flex justify-around">
  //                         <Tooltip label={"Guardar"}>
  //                           <IconCircleCheck
  //                             color="#7bc62d"
  //                             style={{
  //                               width: 25,
  //                               height: 25,
  //                             }}
  //                             data-testid="saveSupport"
  //                             className="pointer"
  //                             onClick={() => handleEditFunction(support, false, "")}
  //                           />
  //                         </Tooltip>
  //                         <Tooltip label={"Cancelar"}>
  //                           <IconCircleX
  //                             color="#ff2825"
  //                             style={{
  //                               width: 25,
  //                               height: 25,
  //                             }}
  //                             data-testid="cancelSupport"
  //                             className="pointer"
  //                             onClick={() => handleCancelFunction(support)}
  //                           />
  //                         </Tooltip>
  //                       </Box>
  //                     ) : (
  //                       <Menu position="bottom-end" withArrow data-testid="menu">
  //                         <Menu.Target>
  //                           <ActionIcon variant="subtle">
  //                             <IconDotsVertical />
  //                           </ActionIcon>
  //                         </Menu.Target>

  //                         <Menu.Dropdown>
  //                           <Menu.Item
  //                             leftSection={<IconPencil />}
  //                             data-testid="editSupport"
  //                             onClick={() => handleEditFunction(support, true)}
  //                           >
  //                             Editar
  //                           </Menu.Item>
  //                           <Menu.Item
  //                             color={isDisabled ? "green" : "red"}
  //                             leftSection={
  //                               <IconCircleFilled
  //                                 style={{
  //                                   width: 10,
  //                                 }}
  //                               />
  //                             }
  //                             data-testid="updateStatusSupport"
  //                             onClick={() => {
  //                               confirmNotification(
  //                                 `Se ${
  //                                   isDisabled ? "habilitará" : "inhabilitará"
  //                                 } el soporte: ${support.filename}`,
  //                                 "¿Está seguro?",
  //                                 () => {
  //                                   handleEditFunction(
  //                                     support,
  //                                     false,
  //                                     isDisabled ? "enabled" : "disabled",
  //                                   );
  //                                 },
  //                               );
  //                             }}
  //                           >
  //                             {isDisabled ? "Habilitar" : "Inhabilitar"}
  //                           </Menu.Item>
  //                         </Menu.Dropdown>
  //                       </Menu>
  //                     )}
  //                   </Box>
  //                 </Grid.Col>
  //               </Grid>
  //             </Card>
  //           );
  //         })}
  //     </Box>
  //   );
  // };
  const renderRow = (option: (typeof supportList.results)[number]) => {
    return (
      <Table.Tr key={option?.corporateClientId}>
        <Table.Td ta={"center"}>{option?.contractId}</Table.Td>

        <Table.Td>{option?.contractName}</Table.Td>

        <Table.Td ta={"end"} className="flex justify-end">
          <TextInput placeholder="Escribir..." w={"60%"} />
        </Table.Td>
        <Table.Td ta="center" className=" justify-center place-center primary-color">
          <IconCircleMinus />
        </Table.Td>
      </Table.Tr>
    );
  };
  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"center"}>CUMS</Table.Th>
            <Table.Th>Descripción</Table.Th>
            <Table.Th ta={"end"}>Valor unitario</Table.Th>
            <Table.Th ta={"center"}></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };
  const renderComponent = () => {
    return (
      <Template
        title={`${action === "create" ? "Crear" : "Editar"} manual tarifario`}
        goBackButton={() => {
          navigate(routes.getTariffManual);
        }}
      >
        <Box>
          <div className="">
            {filterSection()}
            {renderTable()}

            <Box className="flex justify-end gap-3" w={"100%"} py={"xl"}>
              <Button variant="outline" data-testid="goBack2" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
              {action === "create" && (
                <Button
                  variant="filled"
                  data-testid="saveCreateSupport"
                  // onClick={onSubmit}
                >
                  Guardar
                </Button>
              )}
            </Box>
          </div>
        </Box>
      </Template>
    );
  };

  return renderComponent();
};

export default HandleTariffManual;
